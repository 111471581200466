import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import { Link } from 'gatsby';

class ChangeShapers extends React.Component {
    constructor(props) {
        super(props);

        this.timeout = null;

        const faces = [
            {
                id: 'borys-sosnicki',
                html: (<Link to="/changeshapers#borys-sosnicki" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/BorysSosnicki.jpg" alt="Borys Sośnicki" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Borys Sośnicki</strong>
                </Link>)
            },
            {
                id: 'wawrzyniec-sokolowski',
                html: (<Link to="/changeshapers#wawrzyniec-sokolowski" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/WawrzyniecSokołowski.jpg" alt="Wawrzyniec Sokołowski" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Wawrzyniec Sokołowski</strong>
                </Link>)
            },
            {
                id: 'martyna-zapalska',
                html: (<Link to="/changeshapers#martyna-zapalska" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/MartynaZapalska.jpg" alt="Martyna Zapalska" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Martyna Zapalska</strong>
                </Link>)
            },
            {
                id: 'natalia-szczucka',
                html: (<Link to="/changeshapers#natalia-szczucka" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/NataliaSzczucka.jpg" alt="Natalia Szczucka" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Natalia Szczucka</strong>
                </Link>)
            },
            {
                id: 'rafal-gorniak',
                html: (<Link to="/changeshapers#rafal-gorniak" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/RafalGoorniak.jpg" alt="Rafał Górniak" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Rafał Górniak</strong>
                </Link>)
            },
            {
                id: 'krzesimir-koniecko',
                html: (<Link to="/changeshapers#krzesimir-koniecko" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/KrzesimirKoniecko.jpg" alt="Krzesimir Koniecko" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Krzesimir Koniecko</strong>
                </Link>)
            },
            {
                id: 'paulina-natkaniec',
                html: (<Link to="/changeshapers#paulina-natkaniec" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/PaulinaNatkaniec.jpg" alt="Paulina Natkaniec" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Paulina Natkaniec</strong>
                </Link>)
            },
            {
                id: 'aleksandra-milewska',
                html: (<Link to="/changeshapers#aleksandra-milewska" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/AleksandraMilewska.jpg" alt="Aleksandra Milewska" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Aleksandra Milewska</strong>
                </Link>)
            },
            {
                id: 'karolina-folta',
                html: (<Link to="/changeshapers#karolina-folta" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/KarolinaFolta.jpg" alt="Karolina Folta" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Karolina Folta</strong>
                </Link>)
            },
            {
                id: 'maksymilian-czekaj',
                html: (<Link to="/changeshapers#maksymilian-czekaj" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/MaksymilianCzekaj.jpg" alt="Maksymilian Czekaj" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Maksymilian Czekaj</strong>
                </Link>)
            },
            {
                id: 'alina-paharzhelskaya',
                html: (<Link to="/changeshapers#alina-paharzhelskaya" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Alina_Paharzhelskaya.jpg" alt="Alina Paharzhelskaya" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Alina Paharzhelskaya</strong>
                </Link>)
            },
            {
                id: 'iga-swiatek',
                html: (<Link to="/changeshapers#iga-swiatek" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Iga_Swiatek.jpg" alt="Iga Świątek" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Iga Świątek</strong>
                </Link>)
            },
            {
                id: 'natalia-biernacka',
                html: (<Link to="/changeshapers#natalia-biernacka" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/NataliaBiernacka.jpg" alt="Natalia Biernacka" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Natalia Biernacka</strong>
                </Link>)
            },
            {
                id: 'martyna-wawrzen',
                html: (<Link to="/changeshapers#martyna-wawrzen" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/MartynaWawrzen.jpg" alt="Martyna Wawrzeń" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Martyna Wawrzeń</strong>
                </Link>)
            },
            {
                id: 'hubert-swierad',
                html: (<Link to="/changeshapers#hubert-swierad" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/HubertSwierad.jpg" alt="Hubert Świerad" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Hubert Świerad</strong>
                </Link>)
            },
            {
                id: 'pola-stefaniak',
                html: (<Link to="/changeshapers#pola-stefaniak" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/PolaStefaniak.jpg" alt="Pola Stefaniak" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Pola Stefaniak</strong>
                </Link>)
            },
            {
                id: 'karolina-olender',
                html: (<Link to="/changeshapers#karolina-olender" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/KarolinaOlender.jpg" alt="Karolina Olender" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Karolina Olender</strong>
                </Link>)
            },
            {
                id: 'filip-bogdal',
                html: (<Link to="/changeshapers#filip-bogdal" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Filip_Bogdal.jpg" alt="Filip Bogdał" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Filip Bogdał</strong>
                </Link>)
            },
            {
                id: 'kamila-bielecka',
                html: (<Link to="/changeshapers#kamila-bielecka" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Kamila_Bielecka.jpg" alt="Kamila Bielecka" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Kamila Bielecka</strong>
                </Link>)
            },
            {
                id: 'karolina-brydak',
                html: (<Link to="/changeshapers#karolina-brydak" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Karolina_Brydak.jpg" alt="Karolina Brydak" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Karolina Brydak</strong>
                </Link>)
            },
            {
                id: 'maksymilian-okonski',
                html: (<Link to="/changeshapers#maksymilian-okonski" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Maks_Okonski.jpg" alt="Maksymilian Okoński" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Maksymilian Okoński</strong>
                </Link>)
            },
            {
                id: 'justyna-stanula',
                html: (<Link to="/changeshapers#justyna-stanula" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Justyna_Stanula.jpg" alt="Justyna Stanula" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Justyna Stanula</strong>
                </Link>)
            },
            {
                id: 'dominik-surowiec',
                html: (<Link to="/changeshapers#dominik-surowiec" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/DominikSurowiec.jpg" alt="Dominik Surowiec" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Dominik Surowiec</strong>
                </Link>)
            },
            {
                id: 'agnieszka-kopacz',
                html: (<Link to="/changeshapers#agnieszka-kopacz" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/AgnieszkaKopacz.jpg" alt="Agnieszka Kopacz" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Agnieszka Kopacz</strong>
                </Link>)
            },
            {
                id: 'małgorzata-tobis',
                html: (<Link to="/changeshapers#małgorzata-tobis" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/MałgorzataTobis.jpg" alt="Małgorzata Tobis" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Małgorzata Tobis</strong>
                </Link>)
            },
            {
                id: 'alisa-kushnyrenko',
                html: (<Link to="/changeshapers#alisa-kushnyrenko" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/AlisaKushnyrenko.jpg" alt="Alisa Kushnyrenko" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Alisa Kushnyrenko</strong>
                </Link>)
            },
            {
                id: 'aleksandra-banasiak',
                html: (<Link to="/changeshapers#aleksandra-banasiak" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/Aleksandra_Banasiak.jpg" alt="Aleksandra Banasiak" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Aleksandra Banasiak</strong>
                </Link>)
            },
            {
                id: 'dominika-ziolkowska',
                html: (<Link to="/changeshapers#dominika-ziolkowska" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/DominikaZiolkowska.jpg" alt="Dominika Ziółkowska" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Dominika Ziółkowska</strong>
                </Link>)
            },
            {
                id: 'barbara-wywial',
                html: (<Link to="/changeshapers#barbara-wywial" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/BarbaraWywial.jpg" alt="Barbara Wywiał" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Barbara Wywiał</strong>
                </Link>)
            },
            {
                id: 'marcin-miszkiel',
                html: (<Link to="/changeshapers#marcin-miszkiel" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/MarcinMiszkiel.jpg" alt="Marcin Miszkiel" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Marcin Miszkiel</strong>
                </Link>)
            },
            {
                id: 'iga-friedrich',
                html: (<Link to="/changeshapers#iga-friedrich" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/igafriedrich.jpg" alt="Iga Friedrich" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Iga Friedrich</strong>
                </Link>)
            },
            {
                id: 'dominika-szjegiec',
                html: (<Link to="/changeshapers#dominika-szjegiec" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/DominikaSzejgiec.jpg" alt="Dominika Szjegiec" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Dominika Szjegiec</strong>
                </Link>)
            },
            {
                id: 'natalia-ozarek',
                html: (<Link to="/changeshapers#natalia-ozarek" className="block absolute inset-0 overflow-hidden group">
                    <StaticImage src="../images/changeshapers/NataliaOzarek.jpg" alt="Natalia Ożarek" className="aspect-square a w-full h-full group-hover:scale-110 transition-transform" />
                    <strong className="flex absolute inset-x-0 bottom-0 p-4 text-white text-2xl font-semibold translate-y-full group-hover:translate-y-0 transition-transform">Natalia Ożarek</strong>
                </Link>)
            }
        ];

        //.sort(() => 0.5 - Math.random())

        this.state = {
            used: faces.slice(0, 12),
            unused: faces.slice(12)
        };
    }

    componentDidMount() {
        this.timeout = window.setTimeout(() => {
            this.replaceElement();
        }, (Math.floor(Math.random() * (3 - 1)) + 1) * 1000);
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeout);
        this.timeout = null;
    }

    replaceElement () {
        const randKey = Math.floor(Math.random() * 12);

        let used = this.state.used;
        let unused = this.state.unused;

        const oldEl = used[randKey];
        const newEl = unused.splice(Math.floor(Math.random() * unused.length), 1);

        used[randKey] = newEl[0];
        unused.push(oldEl);

        this.setState({
            used: used,
            unused: unused
        });

        this.timeout = window.setTimeout(() => {
            this.replaceElement();
        }, (Math.floor(Math.random() * (3 - 1)) + 1) * 1000);
    }

    render () {
        return (
            <section id="changeshapers">
                <ul className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
                    {this.state.used.map(face => (
                        <li key={face.id} className="relative aspect-square" data-key={face.id}>
                            {face.html}
                        </li>
                    ))}
                </ul>
            </section>
        );
    }
}

export default ChangeShapers;