import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from '../components/layout';
import Container from '../components/container';
import icons from '../images/icons.svg';
import ChangeShapers from "../components/chengeshapers";
import SubmitForm from "../components/form";

const IndexPage = () => (
    <Layout>
        <section className="relative">
            <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]"  />
            <Container>
                <div className="flex flex-col min-h-[95vh] justify-end pb-11 md:pb-14 xl:pb-20 pt-[4.8125rem] md:pt-[6.8125rem]">
                    <h1 className="text-white text-4xl font-semibold mb-4 md:text-6xl xl:text-8xl">#ChangeShapers</h1>
                    <h2 className="text-white text-2xl font-serif mb-6 md:text-4xl xl:text-5xl md:mb-9">Zaangażowanie ma znaczenie.</h2>

                    <ul className="text-white space-y-2 xl:max-w-2xl">
                        <li className="pl-9 bg-list-check bg-no-repeat bg-left-top">Unikalny program rozwojowy oparty o formułę edukacji rówieśniczej</li>
                        <li className="pl-9 bg-list-check bg-no-repeat bg-left-top">Praktyczna wiedza dotycząca wszystkich obszarów studiów przekazywana z&nbsp;pierwszej ręki</li>
                        <li className="pl-9 bg-list-check bg-no-repeat bg-left-top">Inspirujące spotkania realizowane w formie offline lub online</li>
                    </ul>
                </div>
            </Container>
        </section>

        <ChangeShapers />

        <section id="o-projekcie">
            <Container>
                <div className="py-12 md:py-16 xl:grid xl:grid-cols-12 xl:gap-12">
                    <p className="font-semibold text-xl mb-6 md:text-2xl md:leading-7 xl:col-span-5">Ideą  <span className="text-purple-1">#ChangeShapers</span> jest realizacja programu, który poprzez inspirujące spotkania opierające się na edukacji rówieśniczej z jednej strony wspiera rozwój aktywnych studentek i studentów, a z drugiej daje praktyczną edukację oraz bezcenną wiedzę o wszelkich aspektach studiowania przedstawicielom szkół średnich z całej Polski. </p>
                    <p className="leading-7 md:text-lg md:leading-8 xl:col-span-2 xl:col-span-7">Wzmacniamy potencjał Młodych Talentów reprezentujących wybrane organizacje studenckie, samorządy, koła naukowe oraz stowarzyszenia poprzez dedykowany cykl szkoleń, przygotowujących do prowadzenia spotkań. Kreujemy też przestrzeń do dzielenia się wiedzą, doświadczeniami i praktycznymi przeżyciami z okresu studiów. Przedstawiciele szkół średnich i studenci, uczniowie z Ukrainy, będący adresatami projektu, mogą czerpać inspirację, motywację, a także poznać prawdziwą codzienność oraz nieograniczone możliwości, jakie daje czas studiów.</p>
                </div>
            </Container>
        </section>

        <section id="organizacje" className="bg-light-gray py-12 md:py-16">
            <Container>
                <h3 className="text-2xl font-semibold mb-8 md:text-4xl md:mb-12">Organizacje współpracujące</h3>

                <ul className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/AEGEE_Krakow.jpg" width={180} height={80} alt="Stowarzyszenie Europejskie Forum Studentów AEGEE-Kraków" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/BEST-AGH.jpg" width={180} height={80} alt="Stowarzyszenie Studentów BEST AGH Kraków" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/BEST-lodz.jpg" width={180} height={80} alt="Stowarzyszenie Studentów BEST PŁ" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/Conquest_logo.jpg" width={180} height={80} alt="Badania Rynku i Doradztwo Biznesowe ConQuest Consulting" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/ESN-EYE.jpg" width={180} height={80} alt="ESN-EYE Łódź" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/InternationalChallenge_UE_Kato_logo.jpg" width={180} height={80} alt="International Challenge" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/KIS_ALK.jpg" width={180} height={80} alt="Kozminski International Society" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/KN_Kobra.jpg" width={180} height={80} alt="Koło Naukowe Technologii i Organizacji Budowy KOBRa" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/KołoNaukoweRachunkowosci_UEK.jpg" width={180} height={80} alt="Koło Naukowe Rachunkowości Uniwersytetu Ekonomicznego w Krakowie" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/LogoURSS_rozwiniete_green.jpg" width={180} height={80} alt="Uczelniana Rada Samorządu Studentów AGH" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/NZS_UW.jpg" width={180} height={80} alt="Niezależne Zrzeszenie Studentów Uniwersytetu Warszawskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/RKN_UJ.jpg" width={180} height={80} alt="Rada Kół Naukowych Uniwersytetu Jagiellońskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/Samorząd_ALK.jpg" width={180} height={80} alt="Samorząd Studencki Akademii Leona Koźmińskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SF_BCC.jpg" width={180} height={80} alt="Fundacja Studenckie Forum Business Centre Club" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SKNF_UW.jpg" width={180} height={80} alt="Studenckiego Koła Naukowego Finansów Uniwersystetu Warszawskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SS_UAM.jpg" width={180} height={80} alt="Samorząd studentów Uniwersystetu Adama Mickiewicza" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SS_WNE_UW.jpg" width={180} height={80} alt="Samorząd Studentów Wydziału Nauk Ekonomicznych Uniwersytetu Warszawskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/WomeninOrganisations_ALK.jpg" width={180} height={80} alt="Women in Organisations Akademii Leona Koźmińskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                </ul>
            </Container>
        </section>

        <section className="relative py-12 md:py-16 xl:py-24">
            <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]" />
            <Container>
                <h3 className="text-2xl text-white mb-8 font-semibold md:text-4xl md:mb-12">Korzyści dla uczestników</h3>

                <ul className="text-white font-semibold grid grid-cols-1 gap-6 leading-5 tracking-tight md:grid-cols-2 md:gap-8 xl:grid-cols-4 xl:gap-12">
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-1'} />
                        </svg>
                        Networking z innymi aktywnymi studentami
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-2'} />
                        </svg>
                        Praktyczna wiedza o przebiegu studiów na określonych kierunkach
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-3'} />
                        </svg>
                        Inspiracje dotyczące najefektywniejszego wykorzystania studiów
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-4'} />
                        </svg>
                        Informacje o działalności w organizacjach studenckich, samorządach, kołach naukowych z pierwszej ręki
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-5'} />
                        </svg>
                        Sesje Q&A, w trakcie których znajdziesz odpowiedź na wszelkie pytania
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-6'} />
                        </svg>
                        Certyfikat udziału w projekcie dla Twojej klasy i szkoły
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-7'} />
                        </svg>
                        Spotkania realizowane w formie online lub offline
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-8'} />
                        </svg>
                        Możliwość realnego wpływu na otaczający Cię świat
                    </li>
                </ul>
            </Container>
        </section>

        <section id="rekrutacja" className="py-12 md:py-16 xl:py-24">
            <Container>
                <div className="xl:grid xl:grid-cols-12 xl:gap-12">
                    <div className="mb-8 md:mb-12 xl:col-span-5 xl:mb-0">
                        <h3 className="font-semibold text-2xl mb-4 md:text-4xl">Program #ChangeShapers składa się z kilku etapów</h3>
                        <p className="font-serif text-purple-2 text-lg md:text-2xl">Zaprojektowanych w ten sposób, aby wszystkie zaangażowane osoby, zarówno po stronie uczniów szkół średnich, jak i  zaangażowanych w prowadzenie spotkań studentów, czerpały z niego maksimum możliwości. </p>
                    </div>
                    <div className="xl:col-span-7">
                        <ol className="space-y-2 mb-9" style={{counterReset: 'steps'}}>
                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Zaproszenie do projektu i rekrutacja #ChangeShapers</h4>
                                    <p className="text-sm leading-6">Przedstawiciele Accenture zaprosili najaktywniejsze organizacje studenckie, samorządy, stowarzyszenia oraz koła naukowe, do których skierowane zostały zaproszenia do dołączenia do projektu. Zainteresowani członkowie wspomnianych organizacji wzięli udział w procesie weryfikacji i wyboru, na podstawie którego powstała pierwsza grupa #ChangeShapers.</p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Szkolenia i warsztaty</h4>
                                    <p className="text-sm leading-6">#ChangeShapers przeszli specjalnie stworzony cykl szkoleń i warsztatów, który pozwolił odpowiednio przygotować się do poprowadzenia spotkań z koleżankami i kolegami ze szkół średnich.  Dodatkowo realizowano osobiste konsultacje, mające na celu wydobycie maksymalnego potencjału z przygotowanych przez #ChangeShapers treści. </p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Spotkania z uczniami szkół średnich i gośćmi z Ukrainy</h4>
                                    <p className="text-sm leading-6">Głównym punktem programu są spotkania z klasami i przedstawicielami szkół średnich, realizowane przez #ChangeShapers na terenie całej Polski w formie offline lub online. W ich trakcie przekazywane będą praktyczne informacje o studiach i realiach życia akademickiego. Pełnią one również funkcję inspiracyjno-motywacyjną, pokazując uczestnikom tych spotkań, jak wiele możliwości daje czas studiów. </p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Welcome Packi &amp; Gadżety</h4>
                                    <p className="text-sm leading-6">Doceniając zaangażowanie oraz bazując na dotychczasowej współpracy, przekazujemy zestawy dedykowanych gadżetów dla Kół Naukowych, Samorządów i Organizacji Studenckich oraz Stowarzyszeń, które przydadzą się w trakcie realizacji codziennych zadań i obowiązków. Dodatkowo każdy z #ChangeShapers otrzyma indywidualny welcome pack, który wykorzysta w czasie trwania projektu.</p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Ewaluacja i podsumowanie pierwszej edycji projektu</h4>
                                    <p className="text-sm leading-6">Projekt zostanie dokładnie podsumowany przez uczestników, a na podstawie wyciągniętych wniosków planowane będą dalsze działania w ramach #ChangeShapers, które odbywać się będą w kolejnych semestrach akademickich. </p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Kolejne działania</h4>
                                    <p className="text-sm leading-6 mb-2">Więcej informacji już niebawem ;)</p>
                                    <p className="text-sm leading-6">Zachęcamy do śledzenia informacji w kanałach <a href="https://www.accenture.com/pl-pl" className="text-purple-1 underline">social media Accenture</a>. Wierzymy, że spotkamy się ponownie już wczesną jesienią. </p>
                                </div>
                            </li>
                        </ol>

                        <a href="#formularz" className="block bg-purple-1 text-white text-center font-semibold p-4 text-2xl leading-none hover:bg-purple-2">Zgłoś swoją klasę/szkołę</a>
                    </div>
                </div>
            </Container>
        </section>

        <section className="bg-light-gray py-12 md:py-16 xl:py-24">
            <Container>
                <h3 className="text-2xl font-semibold mb-8 md:text-4xl xl:mb-12">Timeline programu</h3>

                <ol className="grid grid-cols-1 gap-2 md:grid-cols-3 xl:grid-cols-5">
                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-1'} />
                        </svg>
                        <p className="text-sm mb-2.5">Rekrutacja #ChangeShapers</p>
                        <p className="text-sm font-semibold">marzec - maj 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-2'} />
                        </svg>
                        <p className="text-sm mb-2.5">Dedykowane szkolenia i warsztaty dla #ChangeShapers</p>
                        <p className="text-sm font-semibold">maj 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-3'} />
                        </svg>
                        <p className="text-sm mb-2.5">Open Hours - indywidualne konsultacje prezentacji przed spotkaniami</p>
                        <p className="text-sm font-semibold">maj-czerwiec 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-4'} />
                        </svg>
                        <p className="text-sm mb-2.5">Realizacja spotkań z przedstawicielami szkół średnich</p>
                        <p className="text-sm font-semibold">maj - czerwiec 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-5'} />
                        </svg>
                        <p className="text-sm mb-2.5">Podsumowanie i ewaluacja pierwszej edycji projektu</p>
                        <p className="text-sm font-semibold">czerwiec - lipiec 2022</p>
                    </li>
                </ol>
            </Container>
        </section>

        <SubmitForm />

        <section id="zespol" className="bg-light-gray py-12 md:py-16 xl:py-24">
            <Container>
                <h3 className="text-2xl font-semibold mb-8 md:text-4xl xl:mb-12">Poznaj nasz zespół</h3>

                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-12 lg:grid-cols-3 xl:grid-cols-4">
                    <li>
                        <StaticImage src="../images/team/ola-leszczynska.jpg" alt="Ola Leszczyńska" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Ola Leszczyńska</h4>
                        <p className="text-sm">W zespole Talent Brand w Accenture rozwija swoje doświadczenia z employer brandingiem i marketingiem rekrutacyjnym. Absolwentka Psychologii na Uniwersytecie Warszawskim, wrażliwa na tematy nierówności i stygmatyzacji. Prywatnie miłośniczka gór i wspinaczki, amatorka fotografii analogowej.</p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/kasia-bialecka.jpg" alt="Kasia Białecka" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Kasia Białecka</h4>
                        <p className="text-sm">Pracuje w zespole Talent Brand w Accenture. Zaangażowana Absolwentka UEK i UW, która lata studenckie spędziła bardzo aktywnie działając w organizacjach studenckich, będąc m.in. w zarządzie Parlamentu Studenckiego UEK, studiując na 3 zagranicznych uczelniach czy występując na konferencjach. </p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/tosia-kuriata.jpg" alt="Tosia Kuriata" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Tosia Kuriata</h4>
                        <p className="text-sm">Pracuje w zespole Talent Brand, zarządza relacjami ze środowiskiem akademickim na łódzkich uczelniach i realizuje działania strategii employer brandingowej. Tematy campusowe są jej bliskie, ponieważ sama kontynuuje studia na Uniwersytecie Łódzkim, na kierunku Zarządzanie ze specjalnością Marketing. Jest entuzjastką ogrodnictwa i wyplatania z makramy.</p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/wiola-krolewicz.jpg" alt="Wiola Królewicz" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Wiola Królewicz</h4>
                        <p className="text-sm">Talent Brand Lead w Accenture. Absolwentka studiów dziennych na kierunku Finanse i Rachunkowość na UE w Katowicach oraz studiów podyplomowych Employer Branding na AGH w Krakowie. W wolnym czasie wędruje po górach, biwakuje na campingach oraz pogłębia swoją wiedzę z zakresu EB i HR.</p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/zespol-kapczuk.jpg" alt="Radosław Łyko" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Michał Kapczuk</h4>
                        <p className="text-sm">CEO Higher. Absolwent Wydziału Zarządzania UW. Studiując prowadził Koło Naukowe Finansów WZ UW oraz kreował ogólnopolskie projekty związane z praktyczną edukacją biznesową skierowane do środowiska akademickiego. </p>
                    </li>
                </ul>
            </Container>
        </section>

        <section className="py-12 md:py-16 xl:py-24">
            <Container>
                <div className="grid grid-cols-1 gap-12 xl:grid-cols-2 items-center">
                    <div className="grid grid-cols-2 gap-2 md:gap-6">
                        <StaticImage src="../images/about-1.jpg" alt="O Accenture" className="col-span-2 aspect-[74/51] md:aspect-[160/101]" />
                        <StaticImage src="../images/about-2.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                        <StaticImage src="../images/about-3.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                        <StaticImage src="../images/about-4.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                        <StaticImage src="../images/about-5.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                    </div>

                    <div>
                        <h3 className="text-2xl font-semibold mb-4 md:text-4xl md:mb-12">O Accenture</h3>

                        <p className="font-serif text-lg leading-6 mb-4 md:text-2xl md:leading-8">Głównym partnerem programu jest Accenture - globalna firma, świadcząca profesjonalne usługi w zakresie technologii cyfrowych, chmury obliczeniowej i bezpieczeństwa.</p>
                        <p className="text-sm leading-6 mb-4"><em className="italic">„Na całym świecie jest jedna uniwersalna rzecz w przypadku pracowników Accenture: bardzo zależy nam na tym, co robimy i wpływie, jaki wywieramy na naszych klientów i społeczności. Jest to kwestia osobista dla nas wszystkich”.</em><br /> Julie Sweet, Chief Executive Officer Accenture</p>
                        <p className="text-sm leading-6 mb-4">Dzięki szerokiemu doświadczeniu i specjalistycznej wiedzy naszych ekspertów z ponad 40 branż oferujemy usługi w obszarach: Strategy & Consulting, Song, Technology i Operations przy wykorzystaniu największej na świecie sieci centrów zaawansowanych technologii i inteligentnych operacji.</p>
                        <p className="text-sm leading-6 mb-4">Zatrudniamy 699 000 pracowników, którzy w codziennej pracy wykorzystują potencjał nowych technologii i ludzkiej kreatywności, świadcząc usługi dla Klientów w ponad 120 krajach. Accenture wykorzystuje innowacje do tworzenia wartości i wspólnego sukcesu dla klientów, partnerów i społeczności. W Polsce biura Accenture mieszczą się w Warszawie, Krakowie, Łodzi, Wrocławiu oraz Katowicach. Pracuje w nich ponad 8 600 pracowników. Poznaj nas!</p>
                        <p className="text-sm leading-6 mb-4"><a href="https://accenture.com/pl-pl" className="underline hover:no-underline" target="_blank" rel="noreferrer">https://accenture.com/pl-pl</a></p>
                    </div>
                </div>
            </Container>
        </section>
    </Layout>
)

export default IndexPage
